"use client"

import React from 'react'
import {
    Button,
    Container,
    Form,
    InputGroup,
    Nav,
    Navbar,
    NavDropdown,
    Image,
    Dropdown,
} from "react-bootstrap";
import '../../src/app.css'
import { Link } from 'react-router-dom';

const HomeHeader = ({data}) => {
    return (
        <Navbar collapseOnSelect expand="lg" className="headbg">
            <Container>
                <Navbar.Brand to="/">
                   {data.projectName}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link as={Link} to="/">About</Nav.Link>
                        <Nav.Link as={Link} to="/">Stacks</Nav.Link>
                        <Nav.Link as={Link} to="/">Experience</Nav.Link>
                        <Nav.Link as={Link} to="/">Projects</Nav.Link>
                        <Nav.Link as={Link} to="/">Contact</Nav.Link>
                        <Nav.Link target="_blank" as={Link} to="#">Blog</Nav.Link>
                        {/* <Nav.Link as={Link} to='#' className="contbtnlink headerbtn">
                            <Button variant="outline-secondary" id="button-addon2">Contact Us
                            </Button>
                        </Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default HomeHeader