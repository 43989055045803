import React, { useEffect } from 'react'
import { Image, Button, Form, Nav, Navbar, NavDropdown, Dropdown, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../slices/authSlice';
import { useLogoutMutation } from '../slices/usersApiSlice';

const UserNav = ({handleSideMenu}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [logoutApiCall] = useLogoutMutation();

    // Add this line to get userInfo from Redux state
    const { userInfo } = useSelector((state) => state.auth);

    const handleLogout = () => {
        dispatch(logout()); // This will clear localStorage and Redux state
        navigate('/login');
    };

    useEffect(() => {
        document.body.classList.add('usernav')
        return () => {
            document.body.classList.remove('usernav')
        }
    }, [])

    return (
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary hamiconnav">
            <div className='mobnav'>
                <Navbar.Brand href="#home">LandingFast 🚀</Navbar.Brand>
                <Button onClick={handleSideMenu} className='hamiconbtn'><Image src={`${process.env.PUBLIC_URL}/images/ham.png`} className='hamicon' alt='hamicon' /></Button>
            </div>
        </Navbar>
    )
}

export default UserNav