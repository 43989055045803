import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Col, Row, Image, Button, Form, Modal, Badge } from "react-bootstrap";
import { useGetProjectByProjectnameQuery } from '../slices/usersApiSlice';
import HomeHeader from '../components/HomeHeader';
import Hero from '../components/Hero';
import Stacks from '../components/Stacks';
import Experience from '../components/Experience';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
import Aos from "aos";
import 'aos/dist/aos.css'

const DeveloperPortfolio = () => {

    useEffect(() => {
        Aos.init({ duration: 1000 })

        document.body.classList.add('tylerfolio')
    }, [])

    const { projectName } = useParams(); // Getting projectName from URL params
    const navigate = useNavigate();

    // Fetching the project data based on projectName
    const { data, error, isLoading } = useGetProjectByProjectnameQuery(projectName);

    if (isLoading) {
        return <Loader />; // Show a loading state while data is being fetched
    }

    if (error) {
        return <div>Error loading project data</div>; // Handle error
    }

    // Destructure the data from the response
    const { template, landingPageData, projectName: fetchedProjectName } = data?.project || {};
    const { proImgUrl, tagline, heroHeading, heroSubHeading, facebookUrl, xUrl, InstaUrl, GithubUrl, DribbleUrl, LinkUrl, BehanceUrl, resume, skills, experiences, userProjects } = landingPageData || {};

    return (
        <>
             <HomeHeader data={data?.project} /> 

             <section className=''>

             </section>
        </>
    );
}

export default DeveloperPortfolio;
