import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useGetProjectByProjectnameQuery } from './slices/usersApiSlice';
import DeveloperPortfolio from './templates/DeveloperPortfolio';
import DeveloperPortfolioNew from './templates/DeveloperPortfolioNew';
import TylerDurden from './templates/TylerDurden';
// import RealEstateLanding from '../templates/RealEstateLanding';
// import DoctorLanding from '../templates/DoctorLanding';
// import ClinicLanding from '../templates/ClinicLanding';

const UserLandingPage = () => {
    const params = useParams();
    console.log('Params:', params);

    const { projectName } = params;
    const { 
        data: userData,
        isLoading,
        isError,
        error
    } = useGetProjectByProjectnameQuery(projectName);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        console.error('Error fetching user:', error);
        return <Navigate to="/404" />;
    }

    // Template map object
    const templateMap = {
        'default': DeveloperPortfolio,
        'developer': DeveloperPortfolioNew,
        'tyler': TylerDurden,
        // 'clinic': ClinicLanding,
    };

    // Get the component based on the selectedTemplate
    const TemplateComponent = templateMap[userData.project.template];

    // If the selected template is not found, render 404
    if (!TemplateComponent) {
        return <Navigate to="/404" />;
    }

    // Render the template component
    return <TemplateComponent userData={userData} />;
};

export default UserLandingPage;
