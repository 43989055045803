import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Col, Row, Image, Button, Form, Modal, Badge } from "react-bootstrap";
import { useGetProjectByProjectnameQuery } from '../slices/usersApiSlice';
import HomeHeader from '../components/HomeHeader';
import Hero from '../components/Hero';
import Stacks from '../components/Stacks';
import Experience from '../components/Experience';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader';
import Aos from "aos";
import 'aos/dist/aos.css'

const DeveloperPortfolio = () => {

  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  const { projectName } = useParams(); // Getting projectName from URL params
  const navigate = useNavigate();

  // Fetching the project data based on projectName
  const { data, error, isLoading } = useGetProjectByProjectnameQuery(projectName);

  if (isLoading) {
    return <Loader />; // Show a loading state while data is being fetched
  }

  if (error) {
    return <div>Error loading project data</div>; // Handle error
  }

  // Destructure the data from the response
  const { template, landingPageData, projectName: fetchedProjectName } = data?.project || {};
  const { proImgUrl, tagline, heroHeading, heroSubHeading, facebookUrl, xUrl, InstaUrl, GithubUrl, DribbleUrl, LinkUrl, BehanceUrl, resume, skills, experiences, userProjects } = landingPageData || {};

  return (
    <>
      <HomeHeader data={data?.project} /> {/* Pass the fetched data to components */}
      <section className="homebannerbg">
        <Container className='sitebannercontent'>
          <Row>
            <Col lg={8} className="homecol text-center mx-auto">
              <div className="bannercontent">
                <div className='mt-5'>
                  <span className='proimgbg'><Image src={proImgUrl} alt='profileimage' className='proimg' /></span>
                </div>
                <Badge className='custombadge'>{tagline} <span><Image src='images/star.svg' alt='star' className='badgeimg' /></span></Badge>
                <h2 className="maintitl">{heroHeading}</h2>
                <div className='col-lg-6 mx-auto'>
                  <p className="content">
                    {heroSubHeading}
                  </p>
                </div>
                <div className='btnflex mb-3'>
                  <Link target="_blank" to={GithubUrl} className='btn foliobtn'><span className='me-2'><Image src='images/github.svg' alt='stackicon' className='toolicon' /></span>Github</Link>
                  <Link target="_blank" to={LinkUrl} className='btn foliobtn'><span className='me-2'><Image src='images/linkedin.svg' alt='stackicon' className='toolicon' /></span>Linkedin</Link>
                  <Link target="_blank" to={GithubUrl} className='btn foliobtn'><span className='me-2'><Image src='images/mail.svg' alt='stackicon' className='toolicon' /></span>Email</Link>
                  <Link target="_blank" to={resume} className='btn foliobtn'><span className='me-2'><Image src='images/experience.svg' alt='stackicon' className='toolicon' /></span>Resume</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='techstacks' id='techstacks' data-aos="fade-up">
        <Container>
          <div className='techheader text-center'>
            <Badge className='custombadge my-3'>Tech Stack <span><Image src='images/star.svg' alt='star' className='badgeimg' /></span></Badge>
            <h3 className='h3'>My Tech Toolbox</h3>
            <p className='content'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis, nulla?</p>
          </div>
          <div className='stackflex col-lg-8 mx-auto mt-4'>

            {skills.map((skill, index) => ( // Use parentheses to implicitly return the JSX
              <div key={index} className='stackflexbg'>
                <Image src={skill.icon} className='stackicons' alt={skill.label} /> {/* Use skill.label for alt text */}
                <h6 className='h6 mt-3'>{skill.value}</h6>
              </div>
            ))}
          </div>
        </Container>
      </section>

      <section className='experience' id='experience' data-aos="fade-up">
        <Container>
          <div className='techheader text-center'>
            <Badge className='custombadge my-3'>Experience <span><Image src='images/star.svg' alt='star' className='badgeimg' /></span></Badge>
            <h3 className='h3'>Professional Experiences</h3>
            <p className='content'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis, nulla?</p>
          </div>

          {experiences.map((experience, index) => (
            <div key={index} className="timeline">
              <ul>
                <li>
                  <div className="timelinecontent">
                    <h3>{experience.company}</h3>
                    <p>{experience.role}</p>
                  </div>
                  <div className="time">
                    <h4>
                      {new Date(experience.from).toLocaleDateString('en-IN', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      })}
                    </h4>
                  </div>
                </li>
                <div className='clearclass'></div>
              </ul>
            </div>
          ))}
        </Container>
      </section>
      <section className='projects' id='projects' data-aos="fade-up">
        <Container>
          <div className='techheader text-center'>
            <Badge className='custombadge my-3'>Projects <span><Image src='images/star.svg' alt='star' className='badgeimg' /></span></Badge>
            <h3 className='h3'>Proof Of Work</h3>
            <p className='content'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis, nulla?</p>
          </div>

          <div className='projectflex'>
            {userProjects.map((project, index) => (
              <div key={index} className='projectflexcont text-center'>
                <span className='projimgbg'>
                  <Image src={project.previewImage} alt='projects' className='projectimg' />
                </span>
                <h6 className='h6 mt-3'>{project.projectName}</h6>
                <Link to={project.projectLink} target='_blank' className='btn sitebtn mt-2'>Live Site</Link>
              </div>
            ))}
          </div>

        </Container>
      </section>
      <section className='contact' id='contact' data-aos="fade-up">
        <Container>
          <div className='techheader text-center'>
            <h3 className='h3'>Get In Touch</h3>
            <p className='content'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis, nulla?</p>
          </div>
          <div className='btnflex mb-3'>
            <Link target='_blank' to={GithubUrl} className='btn sitebtn'><span className='me-2'><Image src='images/github.svg' alt='stackicon' className='toolicon' /></span>Github</Link>
            <Link target='_blank' to={LinkUrl} className='btn sitebtn'><span className='me-2'><Image src='images/linkedin.svg' alt='stackicon' className='toolicon' /></span>Linkedin</Link>
            <Link target='_blank' to={GithubUrl} className='btn sitebtn'><span className='me-2'><Image src='images/mail.svg' alt='stackicon' className='toolicon' /></span>Email</Link>
            <Link target='_blank' to={resume} className='btn sitebtn'><span className='me-2'><Image src='images/experience.svg' alt='stackicon' className='toolicon' /></span>Resume</Link>
          </div>
        </Container>
      </section>
    </>
  );
}

export default DeveloperPortfolio;
